.file-preview {
  max-width: 30%;
  min-width: 30%;
  height: 80%;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
}

.preview-wrapper {
  height: 100%;
  display: flex;
  margin-bottom: 20px;
}

.preview-frame {
  height: 100%;
  border-radius: 20px;
  border: none;
  width: 100%;
  background-color: whitesmoke;
}

.preview {
  flex: 60%;
}

.preview-data {
  flex: 40%;
  margin-left: 20px;
}

.section-title {
  margin: 24px 0px 5px;
  text-transform: uppercase;
  color: rgb(94, 108, 132);
  font-size: 16px;
  font-family: CircularStdBold;
  font-weight: normal;
}

.section-data {
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
  color: rgb(23, 43, 77);
  padding: 6px 12px;
}

.preview-action {
  color: #4957fa !important;
  background-color: #4958fa34;
  border-radius: 8px;
  padding: 8px 16px;
  font-family: 'CircularStdMedium';
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  border: 1px solid transparent;
  transition: 0.2s;
  border: 1px solid transparent;
}

.preview-action:hover {
  border: 1px solid #4957fa;
}
