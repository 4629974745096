.dataview {
  display: flex;
  margin: 14px;
  background-color: white;
  width: 60%;
  user-select: none;
  padding: 20px;
  flex-direction: column;
  border-radius: 14px;
  gap: 10px;
}

.folder-list-element {
  padding: 10px 15px;
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #2c3e50;
  font-size: 22px;
  border-radius: 32px;
  transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.content-list-element:hover {
  background-color: whitesmoke;
}

.element-icon {
  color: #4957fa;
  display: flex;
  margin-right: 34px;
}

.element-name {
  /* text-transform: uppercase; */
  font-size: 22px;
  flex: 30%;
  font-family: 'CircularStdMedium';
  display: flex;
}

.element-owner {
  display: flex;
  flex: 16%;
}

.element-type {
  display: flex;
  flex: 17%;
  color: #2c3e50;
}

.element-date {
  flex: 12%;
  /* text-transform: uppercase; */
  font-size: 20px;
}

.element-size {
  flex: 19%;
  display: flex;
  justify-content: center;
  color: #2c3e50;
}

.element-details-button {
  flex: 6%;
  display: flex;
  color: #4957fa;
  justify-content: flex-end;
}

.element-details-button:hover {
  color: #2c3cf5;
}
