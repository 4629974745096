.utility-context-fragment {
  gap: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: white;
  margin-right: 10px;
}

.utility-context-fragment span {
  display: flex;
  cursor: pointer;
  transition: 0.2s;
}

.utility-context-fragment span:first-child:hover {
  color: #3e4acd;
  background-color: white;
  border-radius: 20px;
}

.utility-context-fragment span:last-child {
  padding: 4px 16px;
  background-color: #3e4acd;
  border-radius: 10px;
  font-family: 'CircularStdMedium';
}

.utility-context-fragment span:last-child:hover {
  background-color: #323db4;
  color: white;
}

.fragment-wrapper {
  display: flex;
  align-items: center;
  color: white;
}

.fragment {
  color: white;
  gap: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* margin-left: 5px;
  margin-right: 5px; */
  font-size: 18px;
  padding: 4px 16px;
  border-radius: 10px;
  /* flex-direction: row-reverse; */
}

.fragment:hover {
  background-color: #3e4acd;
}

.utility-bar {
  display: flex;
  margin: 14px 14px 0px 14px;
  background-color: #4957fa;
  border-radius: 14px;
  padding: 12px;
  user-select: none;
}

.utility-folder-path {
  display: flex;
  align-items: center;
}

.clickable {
  cursor: pointer;
}

.utility-bar-text,
.utility-bar-text-lg {
  color: white;
  text-transform: uppercase;
  font-family: 'CircularStdBold';
  display: flex;
}

.icon-text {
  margin-top: 4px;
  opacity: 1;
  font-weight: 300;
  display: none;
}

.utility-bar-info {
  display: none;
  flex: 20%;
  align-items: center;
  color: white;
}

.utility-bar-data-element {
  display: flex;
  margin-right: 24px;
  align-items: center;
}

.utility-bar-data-text {
  color: white;
  font-size: 22px;
  margin-left: 10px;
  font-family: 'CircularStdBold';
}

.utility-bar-icon-text {
  margin-left: 10px;
}

.utility-bar-drive-data {
  display: flex;
  align-items: center;
}

.utility-bar-text {
  font-size: 18px;
  display: flex;
  align-items: center;
}

.utility-bar-text-lg {
  font-size: 18px;
  margin-right: 10px;
  text-decoration: underline;
  text-underline-offset: 2px;
  font-family: 'CircularStdBold';
}

.utility-bar-add-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.utility-bar-action-text {
  display: flex;
  position: relative;
  font-size: 18px;
  letter-spacing: 0.08em;
  margin-left: 10px;
  align-items: center;
  /* text-transform: uppercase; */
  font-family: 'CircularStdMedium';
  padding: 4px 12px;
  border-radius: 10px;
  background-color: #3e4acd;
}

.utility-bar-actions {
  display: flex;
  align-items: center;
  color: white;
}

.utility-bar-action-button {
  margin: 0 10px;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.utility-bar-nav {
  display: flex;
  flex: 20%;
  justify-content: space-evenly;
}

.utility-bar-nav::after {
  content: '';
  height: 100%;
  width: 4px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  display: block;
}

.utility-bar-path {
  display: flex;
  flex: 60%;
  align-items: center;
  margin-left: 14px;
}

.utility-bar-icon {
  margin: 4px;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  padding: 4px;
  color: white;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: white;
  color: #2c3e50;
  font-weight: 'CircularStdBold';
  font-size: 100%;
  position: absolute;
  border-radius: 16px;
  padding: 1px 5px 2px 5px;
  padding: 6px 12px;
  bottom: -1.6em;
  left: 100%;
  white-space: nowrap;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}
