.message {
  display: flex;
  text-align: center;
  border-radius: 40px;
  justify-content: center;
  padding: 8px 8px;
  align-items: center;
  gap: 20px;
  font-family: 'CircularStdBold';
  margin-top: 10px;
  color: #4957fa !important;
  background-color: #4958fa34 !important;
}

.full {
  width: 100%;
  /* background-color: #4958fa34;
  color: #4957fa; */
}

.copy-link {
  color: #4957fa !important;
  background-color: #4958fa34;
  border-radius: 20px;
  padding: 6px 18px;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  margin-right: auto;
  border: 1px solid transparent;
  transition: 0.2s;
}

.copy-link:hover {
  border: 1px solid #4957fa;
}

.link {
  display: flex;
  gap: 10px;
  align-items: center;
  flex: 100%;
}

.icon-bg {
  display: flex;
  padding: 10px;
  background: #4958fa34;
  border-radius: 50%;
}

.share-description select {
  outline: none;
  /* border: none; */
  font-family: 'CircularStdBold', Tahoma, Geneva, Verdana, sans-serif;
  color: #2c3e50;
  cursor: pointer;
  font-size: 16px;
  border: none;
  margin-left: -4px;
  border-radius: 6px;
}

.share-description select option {
  background-color: white;
  outline: none;
  border: none;
}

.disabled {
  opacity: 0.4;
  cursor: initial;
}

/* .share-description select option:checked {
  background: #2c3e50;
} */

/* option:hover {
  background-color: whitesmoke;
} */

.share-description select:hover {
  background-color: whitesmoke;
}

.share-type-wrapper {
  display: flex;
  flex-direction: column;
}

.switch-type {
  display: flex;
  margin-left: auto;
}

.share-subtext {
  font-size: 12px;
  color: #818181;
}

.icon-wrapper {
  margin-bottom: 14px;
  cursor: pointer;
  color: #4957fa;
  padding: 8px;
  border-radius: 50%;
  display: flex;
}

.icon-wrapper:hover {
  background-color: whitesmoke;
}

.share {
  display: flex;
  gap: 14px;
  font-family: 'CircularStdBold';
  align-items: center;
  /* margin-left: 40px; */
  color: #4957fa;
  margin-bottom: 8px;
  cursor: pointer;
  padding: 10px;
}

.share-description {
  color: #2c3e50;
}

.link-wrapper {
  display: flex;
  flex: 100%;
}

/* .copy-enter {
  opacity: 0;
}

.copy-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.copy-exit {
  opacity: 1;
}
.copy-exit-active {
  opacity: 0;
  transition: opacity 200ms;
} */
