.upload-queue {
  position: absolute;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  bottom: 0px;
  left: 2%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 4%;
  width: 20%;
  /* box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.75); */
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px;
  transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.extend {
  height: 30%;
}

.upload-queue-header {
  border-radius: 10px;
  color: #656f7d;
  padding: 6px;
  align-items: center;
  display: fleX;
}

/*
  #656f7d
*/

.upload-queue-header-text {
  font-size: 14px;
  flex-grow: 1;
  margin-left: 2px;
  color: #2c3e50;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-family: 'CircularStdBold';
}

.upload-queue-header-actions {
  color: #2c3e50;
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
  color: #4958fa;
  display: flex;
}

.upload-queue-header-actions:hover {
  background-color: whitesmoke;
}

.upload-queue-body {
  display: flex;
  gap: 6px;
  flex-direction: column;
}

.upload-queue-item-icon-wrapper {
  display: flex;
  flex-direction: column;
  flex: 22%;
  gap: 5px;
  font-size: 14px;
  justify-content: center;
  font-family: 'CircularStdMedium';
  align-items: center;
}

.upload-queue-item {
  display: flex;
  align-items: center;
  font-family: 'CircularStdMedium';
  gap: 10px;
  background-color: whitesmoke;
  padding: 8px 12px;
  border-radius: 10px;
  color: #2a2e34;
  cursor: pointer;
}

.upload-queue-item:hover {
  background-color: #f0f1f3;
}

.upload-queue-item-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2c3e50;
  gap: 6px;
  width: 100%;
}

.upload-queue-item-text {
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.upload-queue-item-text b {
  color: #656f7d;
}
