@font-face {
  font-family: 'CircularStdBlack';
  src: url('./assets/fonts/CircularStd-Black.woff2') format('woff2'),
    url('./assets/fonts/CircularStd-Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'CircularStdBold';
  src: url('./assets/fonts/CircularStd-Bold.woff2') format('woff2'),
    url('./assets/fonts/CircularStd-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'CircularStdMedium';
  src: url('./assets/fonts/CircularStd-Medium.woff2') format('woff2'),
    url('./assets/fonts/CircularStd-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'CircularStdBook';
  src: url('./assets/fonts/CircularStd-Book.woff2') format('woff2'),
    url('./assets/fonts/CircularStd-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
