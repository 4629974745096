.login-wrapper {
  width: 400px;
  margin: auto;
  margin-top: 10%;
  background-color: white;
  padding: 32px;
  border-radius: 32px;
}

.title {
  justify-content: center;
  font-size: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 36px;
}

.login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eee;
  overflow: auto;
  opacity: 1;
}
