body {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  font-family: 'CircularStdBook';
  font-weight: normal;
  box-sizing: border-box;
}

.content {
  display: flex;
  flex-direction: row;
  flex: 100%;
}
