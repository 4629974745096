.textarea {
  border: none;
  outline: none;
  padding: 16px 20px;
  background: rgb(242, 242, 242);
  border-radius: 12px;
  font-size: 16px;
  font-family: inherit;
  color: black;
  border-bottom: 2px solid white;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: 0.2s;
  resize: vertical;
  margin-bottom: 18px;
}

.textarea:focus {
  border-bottom: 2px solid #4957fa;
  border-bottom-left-radius: 0px;
  box-sizing: border-box;
  border-bottom-right-radius: 0px;
}
