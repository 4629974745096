.body-section {
  margin-bottom: 20px;
  display: flex;
  font-weight: 500;
  align-items: center;
}

.body-section-icon {
  display: flex;
  margin-right: 14px;
  font-size: 16px;
  color: #4957fa;
}

.body-section-title {
  display: flex;
  font-size: 16px;
  font-family: 'CircularStdMedium';
}

.tags-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

.tag-available {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 10px;
  user-select: none;
}

.tag-available-icon {
  display: flex;
  flex: 20%;
}

.tag-available-name {
  display: flex;
  flex: 35%;
  font-weight: 600;
  color: #2c3e50;
}

.tag-available-description {
  display: flex;
  flex: 45%;
}

.tag-available-active {
  cursor: pointer;
  display: flex;
  color: #4957fa;
}

.modal-action {
  color: #4957fa;
  display: flex;
  margin-right: auto;
  font-size: 18px;
  align-items: center;
  font-weight: 550;
  border-radius: 4px;
  padding: 0 10px;
}

.modal-action-text {
  color: #2c3e50;
  margin-left: 10px;
}
