.add-option {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'CircularStdMedium';
}

.add-option svg {
  color: #4957fa;
}

.add-option-text {
  margin-left: 14px;
  font-size: 18px;
}

.add-option:hover {
  background-color: whitesmoke;
}
