.folder-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  max-height: 34vh;
  padding-right: 16px;
  margin-bottom: 20px;
  font-size: 18px !important;
  user-select: none;
  gap: 4px;
}

.folder-element {
  padding: 10px 15px;
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #4957fa;
  font-size: 18px;
  gap: 16px;
  font-family: 'CircularStdMedium';
  border-radius: 16px;
  transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.home-folder {
  background-color: #4957fa34;
  color: #4957fa;
  text-align: center;
  cursor: pointer;
  padding: 6px;
  border-radius: 10px;
  font-family: 'CircularStdMedium';
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 16px;
}

.folder-element span {
  color: #2c3e50;
}

.selected {
  color: #4957fa !important;
  background-color: #4958fa34 !important;
}

.left-menu {
  margin-right: auto;
  display: flex;
  flex-direction: row;
}

.selected-folder {
  color: #4957fa !important;
  background-color: #4958fa34 !important;
  border-radius: 20px;
  padding: 6px 12px;
}

.navigate-button {
  display: flex;
  align-items: center;
  color: #4957fa;
  margin-right: 8px;
  cursor: pointer;
}
