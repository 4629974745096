.tag-settings-wrapper {
  display: flex;
  justify-content: row;
}

.tag-right-button {
  margin-left: auto;
  color: #4958fa;
  display: flex;
  border-radius: 20px;
  cursor: pointer;
  padding: 6px;
}

.tag-right-button:hover {
  background-color: #4958fa80;
}

.tag-list {
  flex: 50%;
}

.tag-utils {
  flex: 50%;
  padding: 10px;
  background-color: #4958fa34;
}

.tag-icon {
  color: #4958fa;
  display: flex;
}

.tag-list-item {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-family: 'CircularStdMedium';
  padding: 6px 12px;
  border-radius: 20px;
}

.name-input {
  background: transparent;
  outline: none;
  border-radius: 20px;
  padding-left: 10px;
  border: none;
  font-family: 'CircularStdMedium';
  font-size: 16px;
}

.tag-list-item:hover {
  background-color: #4958fa34;
}
