.details-wrapper {
  display: flex;
  border-radius: 14px;
  margin-right: 14px;
  margin-bottom: 14px;
  margin-top: 14px;
  padding: 12px;
  user-select: none;
  background-color: white;
  width: 23%;
  flex-direction: column;
  box-sizing: border-box;
}

.details-header {
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  color: #2c3e50;
}

.details-icon {
  color: #4957fa;
  margin-right: 24px;
  display: flex;
}

.details-text {
  color: #2c3e50;
  font-family: 'CircularStdBold';
  font-size: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 16px;
}

.details-close {
  display: flex;
  color: #4957fa;
  margin-left: auto;
}

.details-rep {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
  color: #4957fa;
}

.tag-wrapper {
  display: flex;
  flex-direction: row;
}

.tag {
  font-size: 16px;
  padding: 6px 18px;
  border-radius: 24px;
  font-weight: 500;
  font-family: 'CircularStdMedium';
  /* border: 2px solid; */
  margin-right: 12px;
  margin-bottom: 8px;
  background-color: #4957fa34;
  color: #4957fa !important;
}

.tag-bar {
  justify-content: center;
  display: flex;
}

.info-element {
  display: flex;
  justify-content: space-between;
  color: #2c3e50;
  font-size: 22px;
  margin-bottom: 18px;
}

.info-key {
  color: #2c3e50;
}

.details-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.info-value {
  color: #2c3e50;
  display: flex;
  align-items: center;
  color: #4957fa;
  gap: 10px;
}

.info-value span {
  color: #2c3e50;
}

.details-description {
  padding: 0 26px 0 26px;
  color: #2c3e50;
  font-size: 20px;
  text-align: justify;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.details-info {
  padding: 26px;
}

.details-action-menu {
  margin-top: auto;
  padding: 2px 18px;
  display: flex;
  justify-content: space-between;
  color: #4957fa;
}

.action {
  cursor: pointer;
  display: flex;
  padding: 8px;
  transition: 0.2s;
  border-radius: 50%;
}
/* content: '';
height: 100%;
width: 4px;
border-radius: 10px;
background: rgb(255, 255, 255);
display: block; */

.action:hover {
  background-color: #4958fa34;
}
