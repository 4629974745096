.button {
  background-color: #ffffff;
  color: #2e3e50;
  outline: none;
  border: none;
  border-radius: 8px;
  font-family: inherit;
  padding: 6px 24px;
  margin-left: 10px;
  opacity: 0.6;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'CircularStdMedium';
  font-weight: 550;
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  font-size: 16px;
}

.button-primary:hover {
  background-color: #3846d7 !important;
}

.button-primary {
  background-color: #4957fa !important;
  color: white !important;
}

.button:hover {
  background-color: #eee;
}

.rotating-border {
  width: max-content;
  background: linear-gradient(90deg, white 50%, transparent 50%),
    linear-gradient(90deg, white 50%, transparent 50%),
    linear-gradient(0deg, white 50%, transparent 50%),
    linear-gradient(0deg, white 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  animation: border-dance 2s infinite linear;
}

@keyframes border-dance {
  0% {
    background-position: 0 0, 100% 100%, 0 100%, 100% 0;
  }
  100% {
    background-position: 100% 0, 0 100%, 0 0, 100% 100%;
  }
}
