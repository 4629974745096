.overlay-wrapper {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.1s height 0s;
  overflow: auto;
}

.overlay {
  background-color: white;
  min-width: 22%;
  max-width: 22%;
  margin-top: 6%;
  border-radius: 20px;
  padding: 10px 10px 10px 10px;
  max-height: 80%;
}

.overlay-header-subtitle {
  font-size: 14px;
  color: gray;
  margin-top: -4px;
}

.overlay-body {
  padding: 0px 30px;
  display: flex;
  user-select: none;
  flex-direction: column;
  flex: 80%;
}

.overlay-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px 25px 20px 25px;
  margin-top: 10px;
}

.overlay-header {
  display: flex;
  justify-content: space-between;
  padding: 18px 20px 15px 30px;
}

.overlay-header-title {
  align-items: center;
  display: flex;
  color: #4957fa;
}

.overlay-header-close {
  color: #4957fa;
  cursor: pointer;
  display: flex;
  padding: 5px;
  place-self: center;
  border-radius: 50%;
}

.overlay-header-close:hover {
  background-color: #eee;
  color: #4957fa;
}

.overlay-header-divider {
  margin: 0px 24px;
  margin-bottom: 20px;
  background: rgb(238, 238, 238);
  border: 2px solid rgb(238, 238, 238);
  border-radius: 4px;
}

.overlay-header-text {
  margin-left: 18px;
  font-size: 24px;
  font-family: 'CircularStdMedium';
  display: flex;
  color: #2c3e50;
  flex-direction: column;
}
