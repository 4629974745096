.input {
  border: none;
  outline: none;
  /* padding: 16px 20px; */
  padding: 0 7px 0 46px;
  background: rgb(242, 242, 242);
  border-radius: 20px;
  font-size: 16px;
  font-family: inherit;
  height: 52px;
  width: 100%;
  color: black;
  border-bottom: 2px solid white;
  /* transition: cubic-bezier(0.165, 0.84, 0.44, 1); */
  transition: 0.2s;
  margin-bottom: 14px;
}

.input-wrapper {
  position: relative;
  display: flex;
  width: 100%;
}

.input:focus::placeholder {
  color: #2e3e50;
}

.input-icon {
  position: absolute;
  top: 14px;
  left: 12px;
  color: rgb(144, 147, 160);
  display: flex;
}

.input:focus {
  /* outline: 2px solid #4957fa; */
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 2px solid #4957fa;
  /* background-color: white !important; */
}

.input:hover {
  /* transition: 0.2s; */
  background-color: rgb(235, 235, 235);
}
