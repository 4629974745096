.file-explorer {
  display: flex;
  margin: 14px 0 14px 14px;
  background-color: white;
  width: 20%;
  user-select: none;
  padding: 20px;
  flex-direction: column;
  border-radius: 14px;
  gap: 10px;
  font-size: 20px;
  color: #2c3e50;
}

.folder {
  margin-left: 14px;
  font-family: 'CircularStdMedium';
}

.folder-name {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  margin-bottom: 16px;
  position: relative;
}

.active:after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #4957fa;
  height: 100%;
  border-radius: 10px;
  right: 0;
}

.folder-icon,
.file-icon {
  color: #4957fa;
  display: flex;
}

.file {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 48px;
  margin-bottom: 16px;
}

.filter-field {
  border-radius: 12px;
}

.context-tools {
  gap: 10px;
  color: #2c3e50;
  display: flex;
  margin-left: auto;
}
