.multi-select {
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: relative;
  width: 100%;
}

.selected-option {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 14px;
  color: #4957fa;
  border-radius: 20px;
  font-family: 'CircularStdMedium';
  font-size: 14px;
  background: #4958fa34;
}

.dropdown {
  z-index: 23;
  position: absolute;
  top: 100%;
  width: 100%;
  margin-top: 4px;
  padding: 4px;
  border-radius: 20px;
  left: 0;
  border-radius: 0 0 4px 4px;
  background: #fff;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px;
}

.select-placeholder {
  padding: 8px 16px;
  color: gray;
  border-radius: 16px;
  background-color: rgb(242, 242, 242);
  cursor: pointer;
}

.select-placeholder:hover {
  background-color: rgb(235, 235, 235);
}

.dropdown-option {
  cursor: pointer;
  display: flex;
  padding: 8px 12px;
  border-radius: 10px;
  color: rgb(23, 43, 77);
  font-size: 16px;
}

.dropdown-option:hover {
  background-color: #4958fa34;
}

.selected-option span {
  display: flex;
  cursor: pointer;
}

.add-more {
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: 'CircularStdMedium';
  font-size: 14px;
  cursor: pointer;
}

.add-more:hover {
  text-decoration: underline;
}

/* .selected-option span:hover {
} */
